<template>
  <div id="dashboard-lom-president">
    <v-container fluid tag="section">
      <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
        <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
      </div>
      <v-container v-if="StartupLoadingFlag">
        <v-row wrap>
          <v-col cols="12" md="4" v-for="n in 6" :key="n">
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!LoadingFlag">
        <v-row wrap>
          <v-col cols="12" md="12" align="center">
            <v-btn
              elevation="30"
              shaped
              tile
              small
              color="#9370d8"
              class="font-size-h6 white--text"
              @click.prevent="refreshPageData"
            >
              Refresh
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="ResultFlag">
        <v-row wrap>
          <v-col cols="12" md="8" align="center">
            <v-card
              :color="rows.TableStyle.Color1"
              :style="rows.TableStyle.Style1"
            >
              <v-card-title>
                <h3>LO President details</h3>
              </v-card-title>
              <v-card-subtitle>
                <h5 class="text-left">
                  <v-row>
                    <v-col cols="12" lg="9" md="8" sm="7">
                      {{ rows.AreaName }} - {{ rows.ZoneName }} -
                      {{ rows.RegionName }} - {{ rows.LomName }}
                    </v-col>
                    <v-col cols="12" lg="3" md="4" sm="5">
                      <span class="text-h5" style="color: black">
                        <b>LO Code - {{ rows.MemberLoCode }}</b>
                      </span>
                    </v-col>
                  </v-row>
                </h5>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-text>
                <v-row wrap>
                  <v-col cols="12" md="3" align="center">
                    <v-img
                      contain
                      max-height="200"
                      :src="rows.ProfilePic"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" md="9" align="center">
                    <b-table
                      striped
                      hover
                      :items="rows.MemberDetails"
                      class="president-details-table"
                      thead-class="d-none"
                    ></b-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <router-link to="/my-profile">
                  <v-btn
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#fc7e50"
                    class="font-size-h6 mr-3 white--text"
                  >
                    Edit Profile
                  </v-btn>
                </router-link>
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  color="#9638b0"
                  class="font-size-h6 mr-3 white--text"
                  @click.prevent="LogoutUser"
                >
                  Logout
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="6">
            <div v-if="rows.VotingCardFlag">
              <v-card class="mt-4 mx-auto">
                <v-sheet
                  class="v-sheet--offset mx-auto"
                  :color="rows.TableStyle.Color2"
                  elevation="3"
                  max-width="calc(100% - 62px)"
                >
                  <v-row>
                    <v-col cols="12">
                      <h4 class="ml-4">LO Votes</h4>
                    </v-col>
                  </v-row>
                </v-sheet>
                <v-card-text class="pt-0">
                  <voting-count></voting-count>
                </v-card-text>
              </v-card>
            </div>

            <v-col cols="12" md="12" align="center">
              <v-card
                :color="rows.TableStyle.Color2"
                :style="rows.TableStyle.Style2"
              >
                <v-card-title><h3>Quick Links</h3></v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row wrap>
                    <v-col cols="12" align="center">
                      <!-- items-per-page.sync="6"  -->
                      <v-data-iterator
                        :items="rows.QuickLinks"
                        :items-per-page="-1"
                        hide-default-footer
                      >
                        <template v-slot:header> </template>

                        <template v-slot:default="props">
                          <v-row>
                            <v-col
                              v-for="item in props.items"
                              :key="item.LinkId"
                              cols="12"
                            >
                              <router-link :to="item.LinkUrl">
                                <v-btn
                                  elevation="30"
                                  shaped
                                  tile
                                  small
                                  :color="item.LinkColor"
                                  :class="item.LinkClass"
                                  :style="item.LinkStyle"
                                >
                                  {{ item.LinkTxt }}
                                </v-btn>
                              </router-link>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-iterator>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-col>

          <!-- <v-col cols="12" md="3">
          <p></p>
          <base-material-stats-card
            height="175px"
            color="#1db954"
            icon="mdi-poll"
            title="First half"
            :value="rows.FirstHalfPaid"
            sub-icon="mdi-tag"
            sub-text="2021 First Half membership"
          />
        </v-col>

        <v-col cols="12" md="3">
          <p></p>
          <base-material-stats-card
            height="175px"
            color="#f4ae01"
            icon="mdi-poll"
            title="Secondt half"
            :value="rows.SecondHalfPaid"
            sub-icon="mdi-tag"
            sub-text="2021 Second Half membership"
          />
        </v-col> -->
        </v-row>

        <v-row wrap>
          <v-col cols="12" md="8" align="center">
            <v-card
              :color="rows.TableStyle.Color3"
              :style="rows.TableStyle.Style3"
            >
              <v-card-title>
                <h3>Growth status</h3>
                <!-- <v-spacer></v-spacer>
                <router-link to="/members/subscription/payment/create">
                  <v-btn
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#fc7e50"
                    class="animate-button1"
                  >
                    Pay subscription
                  </v-btn>
                </router-link> -->
              </v-card-title>
              <v-card-subtitle>
                <h6 class="text-left">
                  <v-row>
                    <v-col cols="12" lg="12" md="8" sm="6">
                      <span class="text-danger">*</span>
                      Statistics includes SMA Members
                    </v-col>
                  </v-row>
                </h6>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-text>
                <v-row wrap>
                  <v-col cols="12" md="12" align="center">
                    <v-data-table
                      class="elevation-1"
                      v-model="selected1"
                      item-key="YearId"
                      :headers="rows.TableHeader1"
                      :items="rows.TableData1"
                      :show-select="false"
                      :items-per-page="50"
                      :search="search1"
                      :single-select="false"
                      :disable-sort="true"
                      :hide-default-footer="true"
                      :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50],
                      }"
                    >
                      <template v-slot:[`item.Growth`]="{ item }">
                        <v-chip :color="item.Color" draggable
                          ><b class="text-white"> {{ item.Growth }} </b></v-chip
                        >
                      </template>
                      <!-- <template v-slot:footer>
                        <v-row wrap>
                          <v-col cols="12" align="center">
                            <h3>Overall Growth: {{ rows.OverallGrowth }}</h3>
                          </v-col>
                        </v-row>
                      </template> -->
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" align="center">
            <v-card
              :color="rows.TableStyle.Color4"
              :style="rows.TableStyle.Style4"
            >
              <v-card-title><h3>MRF Status</h3></v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row wrap>
                  <v-col cols="12" align="center">
                    <!-- items-per-page.sync="6"  -->
                    <v-data-iterator
                      :items="rows.MrfTable"
                      :items-per-page="-1"
                      hide-default-footer
                    >
                      <template v-slot:header> </template>

                      <template v-slot:default="props">
                        <v-row>
                          <v-col
                            v-for="item in props.items"
                            :key="item.LinkId"
                            cols="12"
                          >
                            <router-link :to="item.LinkUrl">
                              <v-btn
                                elevation="30"
                                shaped
                                tile
                                small
                                :color="item.LinkColor"
                                :class="item.LinkClass"
                                :style="item.LinkStyle"
                              >
                                {{ item.LinkTxt }}
                              </v-btn>
                            </router-link>
                          </v-col>
                        </v-row>
                      </template>
                    </v-data-iterator>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <p></p>

        <!-- <v-row>
        <v-col cols="12" m="6">
          <p></p>
          <base-material-card :color="rows.Chart1.Color" class="px-5 py-3">
            <template v-slot:heading>
              <div class="display-2 font-weight-light">
                {{ rows.Chart1.Title }}
              </div>
              <div class="subtitle-1 font-weight-light">
                <p>{{ rows.Chart1.Description }}</p>
              </div>
            </template>
            <v-card-text>
              <chartist
                :data="rows.ChartData1"
                :options="options"
                :responsive-options="emailsSubscriptionChart.responsiveOptions"
                :type="rows.Chart1.Type"
                :style="rows.Chart1.Style"
              />
            </v-card-text>
          </base-material-card>
        </v-col>

        <v-col cols="12" lg="6">
          <p></p>
          <base-material-card :color="rows.Table1.Color" class="px-5 py-3">
            <template v-slot:heading>
              <div class="display-2 font-weight-light">
                {{ rows.Table1.Title }}
              </div>
              <div class="subtitle-1 font-weight-light">
                <p>{{ rows.Table1.Description }}</p>
              </div>
            </template>
            <v-card-text class="text-center">
              <v-data-table
                :headers="rows.TableHeader1"
                :items="rows.TableData1"
              />
              <p></p>
              <h3>
                <router-link to="/members/subscription/payment/create">
                  <a :href="href" @click="navigate" style="color: #737373">
                    Pay membership subscription
                    <v-img
                      src="../../../assets/gifs/click_here.gif"
                      height="50px"
                    />
                  </a>
                </router-link>
              </h3>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row> -->

        <!-- <v-row wrap>
          <v-col cols="12" md="12" align="center">
            <v-card :color="rows.TableStyle.Color5" :style="rows.TableStyle.Style5">
              <v-card-title>
                <h3>
                  {{ rows.Table2.Title }}
                </h3>
                <v-spacer></v-spacer>
                <router-link to="/team-members/lgb/list">
                  <v-btn
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#fc7e50"
                    class="animate-button1"
                  >
                    Assign LGB members
                  </v-btn>
                </router-link>
              </v-card-title>
              <v-card-subtitle
                ><h3>{{ rows.Table2.Description }}</h3></v-card-subtitle
              >
              <v-divider></v-divider>
              <v-card-text>
                <v-row wrap>
                  <v-col cols="12" md="12" align="center">
                    <v-data-table
                      class="elevation-1"
                      v-model="selected1"
                      item-key="YearId"
                      :headers="rows.TableHeader2"
                      :items="rows.TableData2"
                      :show-select="false"
                      :items-per-page="50"
                      :search="search1"
                      :single-select="false"
                      :disable-sort="true"
                      :hide-default-footer="true"
                      :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50],
                      }"
                    >
                      <template v-slot:item.ProfilePic="{ item }">
                        <img width="100" height="100" :src="item.ProfilePic" />
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>
        </v-row> -->

        <!-- <v-row wrap>
          <v-col cols="12" md="12">
            <v-card
              :color="rows.TableStyle.Color6"
              :style="rows.TableStyle.Style6"
            >
              <v-card-title><h3>JCI India Calendar</h3></v-card-title>
              <v-card-subtitle></v-card-subtitle>
              <v-divider></v-divider>
              <v-card-text>
                <v-row class="fill-height">
                  <v-col>
                    <v-sheet height="64">
                      <v-toolbar flat>
                        <v-btn
                          outlined
                          class="mr-4"
                          color="grey darken-2"
                          @click="setToday"
                        >
                          Today
                        </v-btn>
                        <v-btn
                          fab
                          text
                          small
                          color="grey darken-2"
                          @click="prev"
                        >
                          <v-icon small> mdi-chevron-left </v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          text
                          small
                          color="grey darken-2"
                          @click="next"
                        >
                          <v-icon small> mdi-chevron-right </v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                          {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-menu bottom right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              outlined
                              color="grey darken-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span>{{ typeToLabel[type] }}</span>
                              <v-icon right> mdi-menu-down </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="type = 'day'">
                              <v-list-item-title>Day</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                              <v-list-item-title>Week</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                              <v-list-item-title>Month</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = '4day'">
                              <v-list-item-title>4 days</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-toolbar>
                    </v-sheet>
                    <v-sheet height="600">
                      <v-calendar
                        ref="calendar"
                        v-model="focus"
                        color="primary"
                        :events="rows.EventsData"
                        :event-color="getEventColor"
                        :type="type"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                      ></v-calendar>
                      <v-menu
                        v-model="selectedOpen"
                        :close-on-content-click="false"
                        :activator="selectedElement"
                        offset-x
                      >
                        <v-card color="grey lighten-4" min-width="350px" flat>
                          <v-toolbar :color="selectedEvent.color" dark>
                            <v-btn icon>
                              <v-icon>mdi-information</v-icon>
                            </v-btn>
                            <v-toolbar-title
                              v-html="selectedEvent.name"
                            ></v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon>
                              <v-icon>mdi-heart</v-icon>
                            </v-btn>
                            <v-btn icon>
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-card-text>
                            <span v-html="selectedEvent.details"></span>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              text
                              color="secondary"
                              @click="selectedOpen = false"
                            >
                              Cancel
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->

        <dashboard-calendar :rows="rows" />

        <v-row wrap>
          <v-col cols="12" lg="12"> </v-col>
        </v-row>

        <p></p>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import DashboardCalendar from "@/view/pages/erp/dashboard/DashboardCalender.vue";
import VotingCount from "@/view/pages/erp/lom/lom-information/VotingCount.vue";

export default {
  mixins: [common],
  name: "DashboardDashboard",
  components: {
    DashboardCalendar,
    VotingCount,
  },
  data() {
    return {
      LoadingFlag: false,
      StartupLoadingFlag: false,

      rows: {
        Chart1: {},
        ChartData1: {},
        TableBgColor1: "",
        TableBgColor2: "",
        TableBgColor3: "",
        TableBgColor4: "",
        TableBgColor5: "",
        ChartOptions1: {},
        Table1: {
          Title: "",
          Description: "",
        },
        TableHeader1: [],
        TableData1: [],
        Table2: {
          Title: "",
          Description: "",
        },
        TableHeader2: [],
        TableData2: [],
        EventsData: [],
      },
      ResultFlag: false,
      LoadingFlag: false,

      selected1: [],
      search1: "",

      itemsPerPage: 4,
      items: [
        {
          name: "Frozen Yogurt",
        },
        {
          name: "Ice cream sandwich",
        },
        {
          name: "Eclair",
        },
        {
          name: "Cupcake",
        },
      ],

      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      emailsSubscriptionChart: {
        data: {
          labels: ["2020 - I", "2020 - II", "2021 - I", "2020 - II"],
          series: [[25, 30, 28, 40]],
        },
        ChartOptions1: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 100,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      headers: [
        {
          sortable: false,
          text: "Year",
          value: "Year",
          align: "center",
        },
        {
          sortable: false,
          text: "First Half",
          value: "FirstHalf",
          align: "center",
        },
        {
          sortable: false,
          text: "Second Half",
          value: "SecondHalf",
          align: "center",
        },
      ],

      MembersColumn: [
        {
          sortable: false,
          text: "Photo",
          value: "Photo",
          align: "center",
        },
        {
          sortable: false,
          text: "Name",
          value: "Name",
          align: "center",
        },
        {
          sortable: false,
          text: "Designation",
          value: "Designation",
          align: "center",
        },
      ],
      MembersData: [
        {
          Photo: "mani.jpg",
          Name: "Mani",
          Designation: "VP",
        },
        {
          Photo: "raj.jpg",
          Name: "Raj",
          Designation: "VP",
        },
      ],
      tabs: 0,
      tasks: {
        0: [
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
          {
            text: "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false,
          },
          {
            text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: false,
          },
          {
            text: "Create 4 Invisible User Experiences you Never Knew About",
            value: true,
          },
        ],
        1: [
          {
            text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true,
          },
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: false,
          },
        ],
        2: [
          {
            text: "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false,
          },
          {
            text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true,
          },
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
        ],
      },
      list: {
        0: false,
        1: false,
        2: false,
      },

      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
    };
  },
  watch: {
    rows: function () {
      console.log("watch rows");
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var LomCode = 1;
      console.log("LomCode=" + LomCode);

      if (LomCode != null) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/dashboard/user/lom-president";
        var upload = {
          UserInterface: 1,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        // this.rows = {};
        this.StartupLoadingFlag = true;
        this.LoadingFlag = true;
        this.ResultFlag = false;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.StartupLoadingFlag = false;
            thisIns.LoadingFlag = false;
            thisIns.ResultFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.rows = records;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.StartupLoadingFlag = false;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "LOM should not be empty.";
        console.log("error=" + message);
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      console.log("updateRange called");
      console.log("start=" + JSON.stringify(start));
      console.log("end=" + JSON.stringify(end));

      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);
      // const eventCount = 1;
      console.log("eventCount=" + eventCount);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        var temp_event = {
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        };
        console.log("temp_event" + JSON.stringify(temp_event));

        events.push(temp_event);
      }

      this.events = [];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#dashboard-lom-president {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 15px;
  }
  .animate-button1 {
    display: inline-block;
    margin: 0 0.5rem;
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .president-details-table {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.01px;
    font-weight: 400;
    font-style: normal;
  }
  .lom-details-table {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.01px;
    font-weight: 400;
    font-style: normal;
  }
}
</style>