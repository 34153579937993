<template>
  <div id="card">
    <v-card elevation="6">
      <v-card-title>
        Voting Description
        <v-spacer></v-spacer>
        <v-btn icon @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <h4 v-html="recordData.VotingDescription"></h4>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      ResultFlag: false,
    };
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.ResultFlag);
    },
    beforeMount() {
      this.refreshPageData();
    },
  },
};
</script>

<style scoped>
</style>